/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, SideBySide, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/batteries/'], ['en', 'https://www.hear.com/hearing-aids/batteries/'], ['en-US', 'https://www.hear.com/hearing-aids/batteries/'], ['en-CA', 'https://ca.hear.com/hearing-aids/batteries/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "find-the-right-batteries-now",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#find-the-right-batteries-now",
    "aria-label": "find the right batteries now permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Find the right batteries now"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids are small high-performance computers, part of the progressive technology field. Comparable with automobiles, distinguishing features are functionality and integrated electronics. And just like cars, they have to be provided with energy to ensure proper functioning. What is gasoline for cars, are hearing aid batteries for hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The usual choice for batteries are the button cell batteries, a type of micro batteries. As the name might suggest, the small, round, and flat shape resembles a customary button. Whereas in the past mercury and zinc batteries were primarily used, today it is zinc-air-systems that are most popular, characterized by a longer service life."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aid batteries are mainly offered in four different sizes: 10, 312, 13, and 675. Hereby, the basic rule of thumb applies: The bigger the battery, the more energy it can supply. Predetermining factors in choosing the right battery for your device are energy requirements and the design of the respective hearing aid."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-do-hearing-aid-batteries-work",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-do-hearing-aid-batteries-work",
    "aria-label": "how do hearing aid batteries work permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How do hearing aid batteries work?"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/zink-lift-ca.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Zinc-air-systems"), " function according to a simple basic principle: Air penetrates into the casing through perforations on the battery surface and reacts with the contained zinc to produce zinc oxide. The result of this chemical reaction is energy, accounting for the necessary current supply. In order for this reaction to occur only when the hearing aid batteries are in use, the perforations are ", React.createElement(_components.strong, null, "factory-sealed with a colorful protective film."))), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This prevents air from entering through the perforations and reacting with the zinc. After removing the film and inserting the batteries into the hearing aid, there can be a delay depending on the particular battery used, until energy supply is ensured. Once the film is removed, even repositioning cannot prevent the normal self-discharge. Hence, it is recommended to activate the hearing aid battery only when it is actually supposed to be used."), "\n", React.createElement(ButtonCta, {
    copy: "GET IN TOUCH WITH OUR EXPERTS!",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "which-batteries-are-compatible-with-my-hearing-aid",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#which-batteries-are-compatible-with-my-hearing-aid",
    "aria-label": "which batteries are compatible with my hearing aid permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Which batteries are compatible with my hearing aid?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Not every battery matches any given hearing aid. Size is the key. Some devices can only be operated with type 10 hearing aid batteries, others exclusively with type 675 batteries. The most commonly sold type is type 13, primarily used in ", React.createElement(_components.a, {
    href: "/hearing-aids/bte",
    className: "c-md-a"
  }, "behind-the-ear devices (BTE)."), " The various types of hearing aid batteries can be very easily determined according to the standardized color scheme of their protective film: yellow, brown, orange and blue (in order of increasing battery size). With daily use of the device, ", React.createElement(_components.strong, null, "the batteries have an average service life of five days up to three weeks, depending on wearing time and inherent energy capacities.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-alternative-to-hearing-aid-batteries-the-accumulator",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-alternative-to-hearing-aid-batteries-the-accumulator",
    "aria-label": "the alternative to hearing aid batteries the accumulator permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The alternative to hearing aid batteries: the accumulator"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Just as with other electronic devices powered with batteries, hearing aid users can resort to an accumulator to supply energy to their hearing aids. The great advantage of this integrated rechargeable battery system is that batteries will no longer have to be replaced. Replacing hearing aid batteries does no longer apply. Even for a hearing aid that does not include an integrated accumulator, rechargeable batteries can be an option. Accumulators for hearing aids the size of customary button cells are available. A manufacturer-supplied version or a universal charging station is used for the charging process. If you are interested in a hearing aid with an integrated accumulator, our experts will gladly assist you over the telephone."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "correct-storage-of-hearing-aid-batteries-is-important",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#correct-storage-of-hearing-aid-batteries-is-important",
    "aria-label": "correct storage of hearing aid batteries is important permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Correct storage of hearing aid batteries is important"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When batteries are not being used, they should be stored in a place that is not too cool and not too warm. Storage at room temperature is ideal in order to ensure the longest possible energy potential. Moreover, special care should be taken in avoiding damage to the protective film, as otherwise air can penetrate and initiate the chemical processes leading to the discharge of the hearing aid batteries."), "\n", React.createElement(ButtonCta, {
    copy: "GET IN TOUCH WITH OUR EXPERTS!",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
